import * as React from "react";
import {FC, useEffect} from "react";
import Layout from "../../components/Navigation/layout";
import * as style from './logout.module.scss';
import {setBasketID, setBasketAmount} from "../../stores/basket/basketSlice";
import {setKey} from '../../stores/auth/authSlice';
import {setMember, setMemberID, setVendorID} from '../../stores/member/memberSlice';
import {resetRegistration} from '../../stores/registration/registrationSlice';
import {connect, ConnectedProps} from "react-redux";

const mapState = ({basket, member, auth}) => ({
    basket,
    member,
    auth
})

const mapDispatch = {
    setKey,
    setBasketID,
    setBasketAmount,
    setMemberID,
    setMember,
    resetRegistration,
    setVendorID
}
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

const logoutPage: FC<any> = ({location, member, key, auth, setKey, setVendorID, setBasketID, resetRegistration, setMemberID, setMember, setBasketAmount}) => {
    const {state = {}} = location
    const {name} = state ?? {name: ''};

    useEffect(() => {
        setKey(null);
        setBasketID(null);
        setBasketAmount(0);
        setMemberID(null);
        setVendorID(null);
        setMember({});
        resetRegistration();
    }, [])

    return (
        <Layout pageTitle={"Logout"}>
            <div className={style.logoutHolder}>
                <div className={style.background}>
                </div>
                <span className={style.logOutText}> Bis bald{name ? ', ' + name : ''} &#128075;&#127995;</span>
            </div>
        </Layout>
    )
}

export default connector(logoutPage);
